.tableContainerBorder {
    border: solid 1px lightgray;
}

.bankStatementInfoHeight {
    height: 800px;
}


@media (min-width: 0px) {
    .detailCardPrimary {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 20px;
        height: auto;
    }

    .detailCardSecond {
        display: none;
    }

    .datepickerTopPadding {
        margin: 10px;
        padding: 10px;
        display: inline-flex;
    }

    .statementTopSpacing {
        padding-top: 30px;
    }
}

@media (min-width: 1000px) {
    .datepickerTopPadding {
        margin: 10px;
        padding: 10px;
        display: inline-flex;
    }

    .statementTopSpacing {
        padding-top: 20px;
    }
}

@media (min-width: 1280px) {
    .detailCardPrimary {
        margin-left: 80px;
        margin-right: 10px;
        margin-top: 20px;
    }

    .detailCardSecond {
        margin-left: 10px;
        margin-right: 80px;
        margin-top: 20px;
        display: inherit;
    }

    .datepickerTopPadding {
        margin: 10px;
        padding: 10px;
        display: inline-flex;
    }

    .statementTopSpacing {
        padding-top: 20px;
    }

}

.statementDateSeperator {
    display: inline;
    padding: 20px;
}

.datepickerStatementWidth {
    width: 50%;
}

.statementButton {
    float: right;
    margin: 5px;
}

