/*@import '../Themes/VistraTheme.css';*/

.btn-hollow {
    border-color: lightgrey !important;
    background-color: white !important;
    color: black;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    cursor: pointer !important;
}

label {
    font-weight: 500;
}

.MuiDataGrid-menuList{
    z-index:999999999999;
}

@media (max-width: 768px) {

    .MuiDataGrid-panelWrapper {
        display: inline-grid !important;
    }

    .MuiDataGrid-paper {
        position: absolute !important;
        min-width: 350px !important;
    }

    .MuiDataGrid-panel {
        transform: translate3d(10px, 238px, 0px) !important;
    }
}




    .pointer {
        cursor: pointer;
    }

    .MuiBadge-badge {
        color: white;
    }


    .MuiLink-underlineNone:hover {
        color: grey !important;
    }

    .MuiLink-underlineHover:hover {
        color: darkslategrey !important;
    }

    body {
        background-color: var(--background-content-colour) !important;
    }

    .MuiModal-root {
        width: 95%;
        text-align: center;
        margin: auto;
        z-index: 999999999 !important;
    }

.MuiDialog-paper {
    margin: 40px !important;
}


.MuiDialog-container {
    height: auto !important;
    margin-top: 80px;
}

.MuiDialog-root {
    overflow: auto !important;
}



@media (max-width: 768px) {

    .MuiDialog-paper {
        margin: 0px !important;
    }

    .MuiDialog-container {
        height: 80% !important;
        margin-top: 80px;
    }
}


.card-header {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.5px;
}

    .MuiDataGrid-cell {
        padding-top: 10px !important;
        padding-bottom: 8px !important
    }

    .nav-action-bar {
        height: 50px;
        line-height: 50px;
        vertical-align: middle;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .MuiTablePagination-displayedRows {
        padding-top: 15px;
    }

    .MuiTablePagination-selectLabel {
        padding-top: 15px;
    }

    .action-button-position {
        position: fixed;
        right: 10px;
        top: 70px;
        z-index: 999;
        border: 2px solid lightgrey;
        background-color: white;
        border-radius: 8px;
        padding-left: 4px;
        padding-right: 4px;
    }

    .ant-layout-sider {
        z-index: 99999;
    }

    .MuiDataGrid-columnHeaderTitle {
        font-weight: 600 !important;
    }

    .default-card {
        margin: 20px;
    }

    .MuiDataGrid-root {
        border: none !important;
    }

    .default-multi-card {
        margin: 10px;
        padding: 20px;
        margin-bottom: 30px;
    }

    .float-right {
        float: right;
    }

    .no-bottom-padding {
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
    }

    .dashboard-card {
        margin-top: 20px;
        margin-right: 10px;
    }

    .standard-page-card {
        position: absolute;
        margin-top: 20px;
        margin-right: 10px;
    }



    .plugin-card {
        margin-top: 20px;
        margin-right: 10px;
    }

    .card-header {
        font-size: 17px;
    }

    .plugin-content {
        margin: 10px;
    }

    .standard-card {
        margin: 20px;
    }

    .standard-card-content {
        margin: 15px;
    }

    .card {
        border: none;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }

    @media (min-width: 0px) {
        .standard-page-card {
            position: absolute;
        }

        .show-on-portrait-phone {
            display: block;
            margin: 10px;
        }

        .hide-on-portrait-phone {
            display: none;
        }

        .list-page-padding {
            padding: 0px;
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 0px;
            margin-top: 20px;
        }

        .btn-info {
            color: white !important;
        }

        .ant-table-row-expand-icon-cell {
            display: none;
        }

        .standard-margin {
            margin: 0px;
        }
    }

    @media (min-width: 650px) {
        .hide-on-portrait-phone {
            display: inherit;
        }

        .show-on-portrait-phone {
            display: none;
        }

        .standard-page-card {
            position: relative;
        }

        .list-page-padding {
            padding: 25px;
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 15px;
            margin-top: 20px;
        }

        .ant-table-row-expand-icon-cell {
            display: none;
        }

        .standard-margin {
            margin: 10px;
        }
    }

    @media (min-width: 768px) {
        .hide-on-portrait-phone {
            display: inherit;
        }

        .show-on-portrait-phone {
            display: none;
        }

        .standard-page-card {
            position: relative;
        }

        .list-page-padding {
            padding: 25px;
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 15px;
            margin-top: 20px;
        }

        .ant-table-row-expand-icon-cell {
            display: none;
        }

        .standard-margin {
            margin: 30px;
        }
    }

    .btn{
        margin: 1px;
    }

    /* For pdf */
    .react-pdf__Page {
        margin-top: 10px;
    }



.react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
}

    .react-pdf__Page__textContent {
        display: none;
    }

    .react-pdf__Page__annotations.annotationLayer {
        padding: 20px;
        display: none;
    }

    .react-pdf__Page__canvas {
        margin: 0 auto;
    }

    /* For all pages */
    .all-page-container {
        height: 100%;
        max-height: 500px;
        overflow: auto;
    }


    .btn-success {
        --bs-btn-color: var(--button-success-colour-text);
        --bs-btn-bg: var(--button-success-colour);
        --bs-btn-border-color: var(--button-success-colour);
        --bs-btn-hover-color: var(--button-success-colour-text);
        --bs-btn-hover-bg: var(--button-success-colour);
        --bs-btn-hover-border-color: var(--button-success-colour);
        --bs-btn-focus-shadow-rgb: 60,153,110;
        --bs-btn-active-color: var(--button-success-colour-text);
        --bs-btn-active-bg: var(--button-success-colour);
        --bs-btn-active-border-color: var(--button-success-colour);
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: var(--button-success-colour-text);
        --bs-btn-disabled-bg: var(--button-success-colour);
        --bs-btn-disabled-border-color: var(--button-success-colour);
    }

    .btn-danger {
        --bs-btn-color: var(--button-danger-colour-text);
        --bs-btn-bg: var(--button-danger-colour);
        --bs-btn-border-color: var(--button-danger-colour);
        --bs-btn-hover-color: var(--button-danger-colour-text);
        --bs-btn-hover-bg: var(--button-danger-colour);
        --bs-btn-hover-border-color: var(--button-danger-colour);
        --bs-btn-focus-shadow-rgb: 60,153,110;
        --bs-btn-active-color: var(--button-danger-colour-text);
        --bs-btn-active-bg: var(--button-danger-colour);
        --bs-btn-active-border-color: var(--button-danger-colour);
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: var(--button-danger-colour-text);
        --bs-btn-disabled-bg: var(--button-danger-colour);
        --bs-btn-disabled-border-color: var(--button-danger-colour);
    }

    .btn-information {
        --bs-btn-color: var(--button-information-colour-text);
        --bs-btn-bg: var(--button-information-colour);
        --bs-btn-border-color: var(--button-information-colour);
        --bs-btn-hover-color: var(--button-information-colour-text);
        --bs-btn-hover-bg: var(--button-information-colour);
        --bs-btn-hover-border-color: var(--button-information-colour);
        --bs-btn-focus-shadow-rgb: 60,153,110;
        --bs-btn-active-color: var(--button-information-colour-text);
        --bs-btn-active-bg: var(--button-information-colour);
        --bs-btn-active-border-color: var(--button-information-colour);
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: var(--button-information-colour-text);
        --bs-btn-disabled-bg: var(--button-information-colour);
        --bs-btn-disabled-border-color: var(--button-information-colour);
    }



    @media (min-width: 850px) {
        .hide-on-portrait-phone {
            display: inherit;
        }

        .show-on-portrait-phone {
            display: none;
        }

        .standard-page-card {
            position: relative;
        }

        .list-page-padding {
            padding: 25px;
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 15px;
            margin-top: 20px;
        }

        .ant-table-row-expand-icon-cell {
            display: table-cell;
        }
    }

    @media (min-width: 1500px) {
        .list-page-padding {
            padding: 25px;
            margin-left: 50px;
            margin-right: 50px;
            margin-bottom: 50px;
            margin-top: 25px;
        }

        .ant-table-row-expand-icon-cell {
            display: table-cell;
        }
    }

    @media (min-width: 2000px) {
        .list-page-padding {
            padding: 25px;
            margin-left: 150px;
            margin-right: 150px;
            margin-bottom: 150px;
            margin-top: 30px;
        }

        .ant-table-row-expand-icon-cell {
            display: table-cell;
        }
    }

    .ant-layout-content {
        position: relative;
    }

.MuiDataGrid-detailPanel{
    overflow: inherit !important;
}


.MuiDataGrid-row--detailPanelExpanded {
    background-color: #F5F5F5;
}

    .MuiDataGrid-cell:focus-within {
        outline: none !important;
    }

    .appBar {
        z-index: 999999999999 !important;
    }

    .MuiDrawer-docked {
        z-index: 999999999;
    }

    .seperatingLine {
        padding-bottom: 5px;
        margin-bottom: 15px !important;
        border-bottom: 1px solid lightgrey;
    }

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--colour-primary);
    --bs-btn-border-color: var(--colour-primary);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--colour-secondary-alt);
    --bs-btn-hover-border-color: var(--colour-secondary-alt);
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--colour-primary);
    --bs-btn-active-border-color: var(--colour-primary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--colour-primary-alt);
    --bs-btn-disabled-border-color: var(--colour-primary-alt);
}

    .light-grey-divider{
        border: 1px solid lightgrey !important;
    }

    .MuiBadge-badge {
        background-color: var(--pip-colour) !important;
    }

    .MuiStepIcon-root {
        color: var(--colour-secondary-alt) !important;
    }

    .MuiButton-textPrimary {
        color: var(--colour-primary-alt) !important;
    }

    .MuiSnackbar-root {
        padding-left: 50px;
    }

        .MuiSnackbar-root > .MuiAlert-root {
            font-size: 16px;
            border: 1px solid darkgrey;
        }

.MuiDrawer-paper {
    background-color: var(--side-colour-primary) !important;
}

    /*#root .MuiBox-root {
    display: block !important;
}*/
    /*
    #root .MuiBox-root > .MuiBox-root {
        padding: 10px !important;
    }*/