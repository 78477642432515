.tableContainerBorder {
    border: solid 1px lightgray;
}

.filterIcon {
    float: right;
    text-align: right;
    position: absolute;
    right: 20px;
    color: grey;
    top: 8px;
    z-index: 10000;
}

    .filterIcon:hover {
        color: black;
        cursor: pointer;
    }
