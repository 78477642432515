.styletest {
    color: var(--colour-primary)
}

.dashboard-container{
    
}

.css-1gsv261 {
   background-color: white !important;
}


.dashboard-item-padding {
    padding: 15px;
}