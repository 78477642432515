.pdf-button-bar {
    background-color: white;
    padding-top: 10px;
}

/*.MuiModal-root {
    width: 75%;
    text-align: center;
    margin: auto;
    overflow: scroll;
    z-index: 999999999 !important;
}*/

.pdf-button {
    margin: 5px;
    padding: 5px;
    background-color: white !important;
    color: black !important;
}

.react-pdf__Page {
    margin-top: 0px;

}

.react-pdf__message--error {
    color: red;
    background-color: white;
    height: 100%;
}

.pdfModalMargin {
    margin-top: 100px;
}

.react-pdf__Document {
    /* width:fit-content;*/
    height: 600px;
    overflow: scroll;
}

.pdfCloseButton {
    right: 12px;
    position: absolute;
    border: none;
    background-color: white;
}