.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}


@media (min-width: 0px) {
    .tenant-message {
        position: fixed;
        width:auto;
        color: red;
        font-weight: 400;
        font-size: 20px;
        bottom: 0px;
        float: right;
        background-color: white;
        border: 1px solid black;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 10px;
    }
}


@media (min-width: 768px) {
    .tenant-message {
        position: absolute;
        width: 100%;
        text-align: center;
        color: red;
        font-weight: 400;
        font-size: 20px;
        float: inherit;
        background-color: inherit;
        border: none;
        padding-bottom: 10px;
        z-index:-1;
    }
}

    .trigger:hover {
        color: #1890ff;
    }

.appBar {
    color: black !important;
    background-color: white !important;
}

.hover-top-nav-icon > .MuiSvgIcon-root:hover {
    color: var(--icon-colour-hover)
}

.hover-top-nav-icon > div > .MuiSvgIcon-root:hover {
    color: var(--icon-colour-hover)
}

a:hover {
    color: lightgrey !important;
}

.MuiListItemIcon-root {
    color: var(--nav-icon-color) !important;
}

.MuiListItemText-primary {
    color: var(--nav-icon-color) !important;
}

.hideOnPhone {
    display: none !important;
}

.chevronNav {
    color: #c8b9bd !important;
}

.appDrawer {
    color: white !important;
    background-color: white !important;
}

.css-zxdg2z{
    padding: 0px !important
}

.css-19kzrtu {
    padding: 0px !important
}
@media (min-width: 0px) {
    aside {
        min-width: 100% !important;
        max-width: 100% !important;
        width: 100% !important;
    }
}


@media (min-width: 768px) {
    aside {
        min-width: auto !important;
        max-width: auto !important;
        width: auto !important;
    }
}


.logo {
    height: 32px;
    margin: 16px;
}

.ant-layout-sider {
    height: 100vh;
    overflow: auto;
    border-right: 1px solid rgba(0,0,0,.1);
    background-color: var(--colour-primary) !important;
}

.ant-menu-dark {
    background-color: var(--colour-primary) !important;
}

.ant-menu-sub.ant-menu-inline {
    background-color: var(--colour-primary-alt) !important;
}

.ant-menu-dark.ant-menu-submenu {
    background-color: var(--colour-primary-alt) !important;
}

    .ant-menu-dark.ant-menu-submenu > .ant-menu {
        background-color: var(--colour-primary-alt) !important;
    }

.NavTopIcon{
   /* padding-right:30px;*/
   width: 40px !important;
   font-size: 35px !important;
   color: grey;
}

.padding-right-nav {
    padding-right: 10px;
    display: inline-block;
}

.powered-by {
    height: 50px;
    float: right;
    position: absolute;
    bottom: 0px;
    padding-left: 200px;
}

.powered-by-icon {
    height: 50px;
    width: 50px;
    position: absolute;
    bottom: 0px;
}


@media (min-width: 1500px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1400px;
    }
}

@media (min-width: 1600px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1500px;
    }
}

@media (min-width: 1700px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1600px;
    }
}

@media (min-width: 1800px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1700px;
    }
}

@media (min-width: 1900px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1800px;
    }
}

@media (min-width: 2000px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1900px;
    }
}

@media (min-width: 2100px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 2000px;
    }
}

@media (min-width: 0px) {
    .css-2dum1v-MuiDrawer-docked{
        /*display: none;*/
    }

    .powered-by {
        display: none !important;
    }
}

.drawer-close {
    margin-right: 40px !important;
    margin-left: 0px !important;
}

@media (min-width: 640px) {
    .css-2dum1v-MuiDrawer-docked {
        /*display: block !important;*/
    }
}

    @media (min-width: 768px) {
        
        .powered-by {
            display: block !important;
        }
    }

    [data-initials]:before {
        background: white;
        color: grey;
        opacity: 1;
        content: attr(data-initials);
        display: inline-block;
        font-weight: bold;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 0.5em;
        width: 35px;
        height: 35px;
        line-height: 30px;
        text-align: center;
        border: 2px solid grey;
    }

    :where(.css-dev-only-do-not-override-10ed4xt).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
        background-color: transparent;
        border-left: 3px solid grey;
        border-radius: 0px !important;
    }

.powered-by-drawer {
    position: absolute;
    bottom: 10px;
    height: 30px;
    right: 10px;
}

.bc-black-logo {
    position: fixed;
    bottom: 10px;
    right: 10px;
    height: 30px;
}