


.loginSupportText {
    color: #02b2bb !important;
} 

.spaceLeft{
    padding-left:10px;
    font-size: 12px;
}

.pinCode {
    width: 16.66%;
    height: 40px;
    text-align: center;
}

.pinCodeInstructions {
    height: 40px;
    text-align: center;
}

.pinButton {
    width: 200px !important;
    margin-bottom: 20px;
}

.pinButtonContainer {
    text-align: center;
}

.loginAlert {
    border: 1px solid lightgrey;
}

.loginAlertContainer {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}


@media (min-width: 0px) {
    .AuthForm {
        width: 100%;
        border-radius: 8px;
        background-color: white;
        margin: 0px !important;
    }

    .AuthFormContainer {
        align-items: inherit;
        display: flex;
        justify-content: center;
        width: 100vw;
        height: 100vh;
    }

    .loginSupport {
        position: fixed;
        bottom: 0px;
        left: 20px;
        height: 32px;
        top: auto;
    }

    .loginContainer {
        background-color: white;
    }

    .poweredBy {
        position: fixed;
        bottom: 20px;
        right: 20px;
        height: 25px;
    }

    @media screen and (min-aspect-ratio: 1/1) {
        .loginSupport {
            position: fixed;
            bottom: auto;
            left: 20px;
            height: 32px;
            top: 5px;
        }
    }

}

@media (min-width: 768px) {
    .AuthForm {
        width: 700px;
        box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
        border-radius: 8px;
        background-color: white;
        margin: 0px !important;
    }

    .loginSupport {
        position: fixed;
        bottom: 20px;
        left: 20px;
        height: 32px;
        top: auto;
    }

    .AuthFormContainer {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        padding-top: 50px;
    }

    .loginContainer {
        background-color: #002426;
    }

    .poweredBy {
        position: fixed;
        bottom: 20px;
        right: 20px;
        height: 32px;
        display: none;
    }
   
}

@media (min-width: 950px) {
    .AuthFormContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        padding-top: 0px;
    }

    .poweredBy {
        position: fixed;
        bottom: 20px;
        right: 20px;
        height: 32px;
        display: inline-block;
    }
}

.removePadding {
    padding: 0px !important;
}

.loginWrapCard {
    margin-bottom: 10px;
}

.loginLogo {
    padding: 40px;
    text-align: center;
}

@media (min-width: 0px) {
    .hiddenOverflow {
        display: none;
    }
}

@media (min-width: 768px) {
    .hiddenOverflow {
        overflow: hidden;
        display: inherit;
        border-right: 1px solid lightgray;
    }
}

.loginButton {
    width: 100%
}

.loginButtonHolder {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.orHolder {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    position: relative;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

    .orHolder::before {
        position: absolute;
        width: 100px;
        height: 1px;
        top: 24px;
        background-color: #aaa;
        content: '';
        left: 0px;
    }

    .orHolder::after {
        position: absolute;
        width: 100px;
        height: 1px;
        top: 24px;
        background-color: #aaa;
        content: '';
        right: 0px;
    }




.AuthFormContent {
    padding-left: 12%;
    padding-right: 12%;
}

.AuthFormTitle {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
}

label {
    font-size: 14px;
    font-weight: 600 !important;
    color: rgb(34, 34, 34);
}

.secondBackButton {
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 5px;
    color: dodgerblue;
    font-size: 30px;
}

