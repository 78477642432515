.table-button-group-button {
    margin: 2px;
    background-color: ghostwhite;
    color: black;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid darkgrey;
    border-radius: 4px;
}

.table-button-group-button:hover {
   
    background-color:lightgrey;
}

.default-content-padding{
    padding: 15px;
}

.table-button-bar {
    width: 100%;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    background-color: ghostwhite;
    border-bottom: 1px solid lightgrey;
}