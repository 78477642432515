.detailCard {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
}

.detailHeader {
    padding: 0.5rem;
    margin-bottom: 0;
    color: black;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.5px;
    padding-left: 16px;
}

