.tableContainerBorder {
    border: solid 1px lightgray;
}

.authorisationButton {
    float: right;
    margin: 5px;
}

.tablePadding {
    margin-top: 20px; 
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
}

.reasonContainer {
    display: inline-grid;
    margin-top: 20px !important;
}

.marginAuthorisationConfirm {
    margin: 30px !important;
}

.authorisationProcessButton {
    right: 10px !important;
    position: absolute !important;
}

.processDialogAppbar {
    background-color: var(--colour-secondary-alt) !important;
}

.filterIcon {
    float: right;
    text-align: right;
    position: absolute;
    right: 20px;
    color: grey;
    top: 8px;
    z-index: 1000;
}

    .filterIcon:hover {
        color: black;
        cursor: pointer;
    }

.authorisationTitle {
    margin-top: 10px;
    border: 1px solid lightgrey;
    background-color: aliceblue;
    padding: 5px;
    margin-left: 20px;
    margin-right: 20px;
}

.deleteMarginLeft {
    margin-left: 15px;
}
