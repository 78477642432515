.tableContainerBorder {
    border: solid 1px lightgray;
}

.hideElement {
    opacity: 0
}

.paymentSpacing {
    margin: 25px;
}

.largeFont {
    font-size: 25px;
}

.reasonContainer {
    display: inline-grid;
    margin-top: 20px !important;
}

.authorisationTitle {
    margin-top: 10px;
    border: 1px solid lightgrey;
    background-color: aliceblue;
    padding: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 30px;
}


.paymentViewDetail {
    padding-bottom: 20px;
    margin-bottom: 30px !important;
    border-bottom: 1px solid lightgrey;
}

.autoWidth {
    width: auto;
}

.pdfCloseButton {
    float: right;
    border: none;
    background-color: white;
}

.paymentButton {
    float: right;
    margin: 5px;
}

@media (min-width: 0px) {
    .progressPaymentCard {
        display: none;
    }
}

@media (min-width: 1280px) {

    .progressPaymentCard {
        display: inherit;
    }
}

.progressPaymentPadding {
    padding-bottom: 10px;
}

.comingSoonSample {
    position: absolute;
    font-size: 20px;
    margin: 10px;
}