
.twoFactorInfo {
    padding: 20px;
    border-top: solid 1px;
    margin-top: 30px;
    margin-bottom: 30px !important;
    border-bottom: solid 1px;
    background-color: lightgrey;
    text-align: center;
}

.notificationBadge{
    margin-top: 10px;
}

.floatRight {
    float: right;
}

@media (min-width: 0px) {
    .detailCardPrimary {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        height: auto;
    }

    .detailCardSecond {
        display: none;
    }
}

@media (min-width: 1280px) {
    .detailCardPrimary {
        margin-left: 80px;
        margin-right: 10px;
        margin-top: 20px;
    }

    .detailCardSecond {
        margin-left: 10px;
        margin-right: 80px;
        margin-top: 20px;
        display: inherit;
    }
}


.settingsItemPadding {
    padding: 25px;
    margin: 15px;
}
