.flashing-icon {
    animation: flash 2s linear infinite;
}

@keyframes flash {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.8;
        color: darkred;
    }

    100% {
        opacity: 1;
    }
}